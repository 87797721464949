import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/header/logo.png";
import f_logo from "../../Assets/header/Union.png";
import { IoReorderThreeOutline } from "react-icons/io5";
import { RiCloseFill } from "react-icons/ri";
import store from "../../Redux/MyRedux/store";
import { UserProfileInfo } from "../../Redux/MyRedux/action/userActions";

function Sidebar(props) {
  const [userType, setUserType] = useState("");
  const [userChannelId, setUserChannelId] = useState("");
  const [rotexSPTabsAccess, setRotexSPTabsAccess] = useState(false);
  const [rotexCPTabsAccess, setRotexCPTabsAccess] = useState(false);
  let userid = localStorage.getItem("user_id");

  const getProfiledata = () => {
    try {
      store.dispatch(UserProfileInfo(userid)).then((res) => {
        if (res?.isError == false) {
          setRotexSPTabsAccess(res?.data?.IsRotexViewSupplierModule);
          setRotexCPTabsAccess(res?.data?.IsRotexViewCpModule);
        }
      });
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getProfiledata();
  }, []);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    setUserType(usertype);

    setUserChannelId(localStorage.getItem("channelPartnerId"));
    getProfiledata()
  }, []);




  const { pathname } = useLocation();
  const [sidebarOpen, setSideBarOpen] = useState(true);
  props.setSidebarOpen(sidebarOpen);


  const hideCpTabs = () => {
    if (userType === "SP") {
      return false;
    }
    if (userType === "ROTEX" && rotexCPTabsAccess == true) {
      return true;
    }
    if (userType === "ADMIN" || userType === "CP") {
      return true;
    }
    if (userType === "ROTEX" && rotexCPTabsAccess == false) {
      return false;
    }
    return false;
  };

  const hideSpTabs = () => {
    if (userType === "CP") {
      return false;
    }
    if (userType === "ROTEX" && rotexSPTabsAccess == true) {
      return true;
    }
    if (userType === "ADMIN" || userType === "CP") {
      return true;
    }
    if (userType === "ROTEX" && rotexSPTabsAccess == false) {
      return false;
    }
    if (userType === "SP") {
      return true;
    }
    return false;
  };



  return (
    <div
      className={`${sidebarOpen ? "sidebar_open" : "sidebar_close"} sidebar`}
    >
      <div className="sidebar_cont">
        <div className="side_logo_cont">
          {sidebarOpen ? (
            <img src={f_logo} alt="" className="img-fluid small_header_logo" />
          ) : (
            <img src={logo} alt="" className="img-fluid header_logo_img" />
          )}
          <div
            className="side_bar_iconsopen_cont"
            onClick={() => setSideBarOpen(!sidebarOpen)}
          >
            {sidebarOpen ? (
              <IoReorderThreeOutline
                onClick={() => setSideBarOpen(!sidebarOpen)}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <IoReorderThreeOutline
                onClick={() => setSideBarOpen(!sidebarOpen)}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        </div>
      </div>


      <ul className="" style={{ fontFamily: "Poppins" }}>
      {userType == "SP" && (
          <li
            className={
              pathname == `/suppliermaster/fginventory/${userChannelId}` ||
              pathname == `/suppliermaster/fginventory/${userChannelId}`
                ? "active nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link
                className="nav-link"
                to={`/suppliermaster/fginventory/${userChannelId}`}
              >
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33332 0H5.83333C7.67427 0 9.16665 1.49238 9.16665 3.33332V5.83333C9.16665 7.67427 7.67427 9.16665 5.83333 9.16665H3.33332C1.49238 9.16669 0 7.67431 0 5.83333V3.33332C0 1.49238 1.49238 0 3.33332 0Z"
                      fill="white"
                    />
                    <path
                      d="M14.1667 0H16.6667C18.5076 0 20 1.49238 20 3.33332V5.83333C20 7.67427 18.5076 9.16665 16.6667 9.16665H14.1667C12.3258 9.16665 10.8334 7.67427 10.8334 5.83333V3.33332C10.8333 1.49238 12.3257 0 14.1667 0Z"
                      fill="white"
                    />
                    <path
                      d="M3.33332 10.834H5.83333C7.67427 10.834 9.16665 12.3264 9.16665 14.1673V16.6673C9.16665 18.5083 7.67427 20.0006 5.83333 20.0006H3.33332C1.49238 20.0007 0 18.5083 0 16.6674V14.1673C0 12.3264 1.49238 10.834 3.33332 10.834Z"
                      fill="white"
                    />
                    <path
                      d="M14.1666 10.834H16.6666C18.5075 10.834 19.9999 12.3264 19.9999 14.1673V16.6673C19.9999 18.5083 18.5075 20.0007 16.6666 20.0007H14.1666C12.3256 20.0007 10.8333 18.5083 10.8333 16.6674V14.1673C10.8332 12.3264 12.3256 10.834 14.1666 10.834Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {sidebarOpen && <span className="menu_text">Inventory Dashboard</span>}
              </Link>
              {!sidebarOpen && <span className="menu_text"> <Link
                className="nav-link ps-0"
                to={`/suppliermaster/fginventory/${userChannelId}`}
              > Inventory Dashboard </Link> </span>}

            </div>
          </li>
        )}


        {
          hideCpTabs()  &&
          <li
          className={
            pathname === "/orderrecommandation" ||
            pathname === "/orderlistmto"
              ? "active nav-item"
              : "nav-item"
          }
        >
          <div className="dropdown">
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to="/orderrecommandation">
                <div className="icon-image">
                  <svg
                    width="20"
                    height="24"
                    viewBox="0 0 20 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 8.15701V18.5C19.9984 19.9582 19.4184 21.3562 18.3873 22.3873C17.3562 23.4184 15.9582 23.9984 14.5 24H5.49999C4.04179 23.9984 2.64377 23.4184 1.61267 22.3873C0.58156 21.3562 0.00158817 19.9582 0 18.5V5.50002C0.00158817 4.04182 0.58156 2.64379 1.61267 1.61269C2.64377 0.581582 4.04179 0.00161038 5.49999 2.22109e-05H11.843C12.5656 -0.00203834 13.2814 0.139292 13.9489 0.415826C14.6165 0.692361 15.2225 1.0986 15.732 1.61102L18.389 4.26802C18.9013 4.77753 19.3075 5.3836 19.584 6.05115C19.8605 6.7187 20.0019 7.43446 20 8.15701ZM17 18.5V9.00001H13C12.4696 9.00001 11.9608 8.7893 11.5858 8.41423C11.2107 8.03915 11 7.53045 11 7.00002V3.00002H5.49999C4.83695 3.00002 4.20107 3.26341 3.73223 3.73225C3.26339 4.20109 3 4.83698 3 5.50002V18.5C3 19.163 3.26339 19.7989 3.73223 20.2678C4.20107 20.7366 4.83695 21 5.49999 21H14.5C15.163 21 15.7989 20.7366 16.2678 20.2678C16.7366 19.7989 17 19.163 17 18.5Z"
                      fill="white"
                    />
                    <path
                      d="M14.5606 13.5607C14.842 13.2794 15 12.8978 15 12.5C15 12.1022 14.842 11.7206 14.5606 11.4393C14.2793 11.158 13.8978 11 13.5 11H6.5C6.10217 11 5.72064 11.158 5.43934 11.4393C5.15803 11.7206 5 12.1022 5 12.5C5 12.8978 5.15803 13.2794 5.43934 13.5607C5.72064 13.842 6.10217 14 6.5 14H13.5C13.8978 14 14.2793 13.842 14.5606 13.5607Z"
                      fill="#34B1E2"
                    />
                    <path
                      d="M6.5 16H10.5C10.8978 16 11.2793 16.158 11.5607 16.4393C11.842 16.7206 12 17.1022 12 17.5C12 17.8978 11.842 18.2793 11.5607 18.5607C11.2793 18.842 10.8978 19 10.5 19H6.5C6.10217 19 5.72064 18.842 5.43934 18.5607C5.15803 18.2793 5 17.8978 5 17.5C5 17.1022 5.15803 16.7206 5.43934 16.4393C5.72064 16.158 6.10217 16 6.5 16Z"
                      fill="#34B1E2"
                    />
                  </svg>
                </div>
                {sidebarOpen && (
                  <div className="dropdown-content">
                    <div className="flex_div">
                      <Link to="/orderrecommandation">MTA</Link>
                      {/* <Link to="/orderlistmto">MTO</Link> */}
                    </div>
                  </div>
                )}
              </Link>
              {!sidebarOpen && <span className="menu_text"><Link className="nav-link ps-0" to="/orderrecommandation">MTA</Link></span>}
            </div>
          </div>
        </li>
        }
          
         
        
     {
      hideSpTabs() && 
          <li
            className={
              pathname === "/rotex/order" || pathname === "/rotex/order"
                ? "active nav-item"
                : "nav-item"
            }
          >
            <div className="dropdown">
              <div className="d-flex align-items-center gap-2">
                <Link className="nav-link" to="/rotex/order">
                  <div className="icon-image">
                    <svg
                      width="20"
                      height="24"
                      viewBox="0 0 20 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 8.15701V18.5C19.9984 19.9582 19.4184 21.3562 18.3873 22.3873C17.3562 23.4184 15.9582 23.9984 14.5 24H5.49999C4.04179 23.9984 2.64377 23.4184 1.61267 22.3873C0.58156 21.3562 0.00158817 19.9582 0 18.5V5.50002C0.00158817 4.04182 0.58156 2.64379 1.61267 1.61269C2.64377 0.581582 4.04179 0.00161038 5.49999 2.22109e-05H11.843C12.5656 -0.00203834 13.2814 0.139292 13.9489 0.415826C14.6165 0.692361 15.2225 1.0986 15.732 1.61102L18.389 4.26802C18.9013 4.77753 19.3075 5.3836 19.584 6.05115C19.8605 6.7187 20.0019 7.43446 20 8.15701ZM17 18.5V9.00001H13C12.4696 9.00001 11.9608 8.7893 11.5858 8.41423C11.2107 8.03915 11 7.53045 11 7.00002V3.00002H5.49999C4.83695 3.00002 4.20107 3.26341 3.73223 3.73225C3.26339 4.20109 3 4.83698 3 5.50002V18.5C3 19.163 3.26339 19.7989 3.73223 20.2678C4.20107 20.7366 4.83695 21 5.49999 21H14.5C15.163 21 15.7989 20.7366 16.2678 20.2678C16.7366 19.7989 17 19.163 17 18.5Z"
                        fill="white"
                      />
                      <path
                        d="M14.5606 13.5607C14.842 13.2794 15 12.8978 15 12.5C15 12.1022 14.842 11.7206 14.5606 11.4393C14.2793 11.158 13.8978 11 13.5 11H6.5C6.10217 11 5.72064 11.158 5.43934 11.4393C5.15803 11.7206 5 12.1022 5 12.5C5 12.8978 5.15803 13.2794 5.43934 13.5607C5.72064 13.842 6.10217 14 6.5 14H13.5C13.8978 14 14.2793 13.842 14.5606 13.5607Z"
                        fill="#34B1E2"
                      />
                      <path
                        d="M6.5 16H10.5C10.8978 16 11.2793 16.158 11.5607 16.4393C11.842 16.7206 12 17.1022 12 17.5C12 17.8978 11.842 18.2793 11.5607 18.5607C11.2793 18.842 10.8978 19 10.5 19H6.5C6.10217 19 5.72064 18.842 5.43934 18.5607C5.15803 18.2793 5 17.8978 5 17.5C5 17.1022 5.15803 16.7206 5.43934 16.4393C5.72064 16.158 6.10217 16 6.5 16Z"
                        fill="#34B1E2"
                      />
                    </svg>
                  </div>
                  {sidebarOpen && (
                    <div className="dropdown-content">
                      <div className="flex_div">
                        <Link to="/rotex/order">Rotex MTA Orders</Link>
                      </div>
                    </div>
                  )}
                </Link>
                {!sidebarOpen && <span className="menu_text"> <Link className="nav-link ps-0" to="/rotex/order"> Rotex MTA Orders </Link> </span>}
              </div>
            </div>
          </li>
}
       

        {userType == "SP" && (
          <li
            className={
              pathname ===
                `/suppliermaster/productionrecommendation/${userChannelId}` ||
              pathname ===
                `/suppliermaster/productionrecommendation/${userChannelId}`
                ? "active nav-item"
                : "nav-item"
            }
          >
            <div className="dropdown">
              <div className="d-flex align-items-center gap-2">
                <Link
                  className="nav-link"
                  to={`/suppliermaster/productionrecommendation/${userChannelId}`}
                >
                  <div className="icon-image">
                    <svg
                      width="20"
                      height="24"
                      viewBox="0 0 20 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 8.15701V18.5C19.9984 19.9582 19.4184 21.3562 18.3873 22.3873C17.3562 23.4184 15.9582 23.9984 14.5 24H5.49999C4.04179 23.9984 2.64377 23.4184 1.61267 22.3873C0.58156 21.3562 0.00158817 19.9582 0 18.5V5.50002C0.00158817 4.04182 0.58156 2.64379 1.61267 1.61269C2.64377 0.581582 4.04179 0.00161038 5.49999 2.22109e-05H11.843C12.5656 -0.00203834 13.2814 0.139292 13.9489 0.415826C14.6165 0.692361 15.2225 1.0986 15.732 1.61102L18.389 4.26802C18.9013 4.77753 19.3075 5.3836 19.584 6.05115C19.8605 6.7187 20.0019 7.43446 20 8.15701ZM17 18.5V9.00001H13C12.4696 9.00001 11.9608 8.7893 11.5858 8.41423C11.2107 8.03915 11 7.53045 11 7.00002V3.00002H5.49999C4.83695 3.00002 4.20107 3.26341 3.73223 3.73225C3.26339 4.20109 3 4.83698 3 5.50002V18.5C3 19.163 3.26339 19.7989 3.73223 20.2678C4.20107 20.7366 4.83695 21 5.49999 21H14.5C15.163 21 15.7989 20.7366 16.2678 20.2678C16.7366 19.7989 17 19.163 17 18.5Z"
                        fill="white"
                      />
                      <path
                        d="M14.5606 13.5607C14.842 13.2794 15 12.8978 15 12.5C15 12.1022 14.842 11.7206 14.5606 11.4393C14.2793 11.158 13.8978 11 13.5 11H6.5C6.10217 11 5.72064 11.158 5.43934 11.4393C5.15803 11.7206 5 12.1022 5 12.5C5 12.8978 5.15803 13.2794 5.43934 13.5607C5.72064 13.842 6.10217 14 6.5 14H13.5C13.8978 14 14.2793 13.842 14.5606 13.5607Z"
                        fill="#34B1E2"
                      />
                      <path
                        d="M6.5 16H10.5C10.8978 16 11.2793 16.158 11.5607 16.4393C11.842 16.7206 12 17.1022 12 17.5C12 17.8978 11.842 18.2793 11.5607 18.5607C11.2793 18.842 10.8978 19 10.5 19H6.5C6.10217 19 5.72064 18.842 5.43934 18.5607C5.15803 18.2793 5 17.8978 5 17.5C5 17.1022 5.15803 16.7206 5.43934 16.4393C5.72064 16.158 6.10217 16 6.5 16Z"
                        fill="#34B1E2"
                      />
                    </svg>
                  </div>
                  {sidebarOpen && (
                    <div className="dropdown-content">
                      <div className="flex_div">
                        <Link
                          to={`/suppliermaster/productionrecommendation/${userChannelId}`}

                        >
                          Production Recommendation
                        </Link>
                      </div>
                    </div>
                  )}
                </Link>
                {!sidebarOpen && (
                  <span className="menu_text"> <Link
                  className="nav-link ps-0"
                  to={`/suppliermaster/productionrecommendation/${userChannelId}`}
                >Production Recommendation</Link> </span>
                )}
              </div>
            </div>
          </li>
        )}

      

        {(userType == "ADMIN" || (userType == "ROTEX" && rotexCPTabsAccess == true)) && (
          <li
            className={
              pathname == "/dashboard" || pathname == "/inactiveinventory"
                ? "active nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to="/dashboard">
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33332 0H5.83333C7.67427 0 9.16665 1.49238 9.16665 3.33332V5.83333C9.16665 7.67427 7.67427 9.16665 5.83333 9.16665H3.33332C1.49238 9.16669 0 7.67431 0 5.83333V3.33332C0 1.49238 1.49238 0 3.33332 0Z"
                      fill="white"
                    />
                    <path
                      d="M14.1667 0H16.6667C18.5076 0 20 1.49238 20 3.33332V5.83333C20 7.67427 18.5076 9.16665 16.6667 9.16665H14.1667C12.3258 9.16665 10.8334 7.67427 10.8334 5.83333V3.33332C10.8333 1.49238 12.3257 0 14.1667 0Z"
                      fill="white"
                    />
                    <path
                      d="M3.33332 10.834H5.83333C7.67427 10.834 9.16665 12.3264 9.16665 14.1673V16.6673C9.16665 18.5083 7.67427 20.0006 5.83333 20.0006H3.33332C1.49238 20.0007 0 18.5083 0 16.6674V14.1673C0 12.3264 1.49238 10.834 3.33332 10.834Z"
                      fill="white"
                    />
                    <path
                      d="M14.1666 10.834H16.6666C18.5075 10.834 19.9999 12.3264 19.9999 14.1673V16.6673C19.9999 18.5083 18.5075 20.0007 16.6666 20.0007H14.1666C12.3256 20.0007 10.8333 18.5083 10.8333 16.6674V14.1673C10.8332 12.3264 12.3256 10.834 14.1666 10.834Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {sidebarOpen && <span>Dashboard</span>}
              </Link>

              {!sidebarOpen && <span className="menu_text"><Link className="nav-link ps-0" to="/dashboard">Dashboard</Link></span>}
            </div>
          </li>
        )}

        {/* {(userType != "SP" && userType != "CP") && (
          <li
            className={
              pathname == "/rotex/inventory" || pathname == "/rotex/inventory"
                ? "active nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to="/rotex/inventory">
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33332 0H5.83333C7.67427 0 9.16665 1.49238 9.16665 3.33332V5.83333C9.16665 7.67427 7.67427 9.16665 5.83333 9.16665H3.33332C1.49238 9.16669 0 7.67431 0 5.83333V3.33332C0 1.49238 1.49238 0 3.33332 0Z"
                      fill="white"
                    />
                    <path
                      d="M14.1667 0H16.6667C18.5076 0 20 1.49238 20 3.33332V5.83333C20 7.67427 18.5076 9.16665 16.6667 9.16665H14.1667C12.3258 9.16665 10.8334 7.67427 10.8334 5.83333V3.33332C10.8333 1.49238 12.3257 0 14.1667 0Z"
                      fill="white"
                    />
                    <path
                      d="M3.33332 10.834H5.83333C7.67427 10.834 9.16665 12.3264 9.16665 14.1673V16.6673C9.16665 18.5083 7.67427 20.0006 5.83333 20.0006H3.33332C1.49238 20.0007 0 18.5083 0 16.6674V14.1673C0 12.3264 1.49238 10.834 3.33332 10.834Z"
                      fill="white"
                    />
                    <path
                      d="M14.1666 10.834H16.6666C18.5075 10.834 19.9999 12.3264 19.9999 14.1673V16.6673C19.9999 18.5083 18.5075 20.0007 16.6666 20.0007H14.1666C12.3256 20.0007 10.8333 18.5083 10.8333 16.6674V14.1673C10.8332 12.3264 12.3256 10.834 14.1666 10.834Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {sidebarOpen && <span>Rotex Inventory</span>}
              </Link>

              {!sidebarOpen && (
                <span className="menu_text">Rotex Inventory</span>
              )}
            </div>
          </li>
        )} */}

        {(userType == "ADMIN" || (userType == "ROTEX" && rotexCPTabsAccess == true)) && (
          <li
            className={
              pathname === "/custommaster" ||
              pathname == "/inactivecustommaster"
                ? "active  nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to="/custommaster">
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.16667 20.0002V17.5002C9.16667 16.1427 8.08333 15.0443 6.735 15.0068L4.58333 17.5002L2.39917 15.0102C1.06583 15.0643 0 16.1535 0 17.5002V20.0002M20 20.0002V17.5002C20 16.1427 18.9167 15.0443 17.5683 15.0068L15.4167 17.5002L13.2325 15.0102C11.8992 15.0643 10.8333 16.1535 10.8333 17.5002V20.0002"
                      fill="white"
                    />
                    <path
                      d="M12.1375 10.2904C12.1375 12.1287 13.6325 13.6237 15.4709 13.6237C17.3092 13.6237 18.8042 12.1287 18.8042 10.2904C18.8042 8.45203 17.3092 6.95703 15.4709 6.95703C13.6325 6.95703 12.1375 8.45203 12.1375 10.2904Z"
                      fill="#34B1E2"
                    />
                    <path
                      d="M1.3042 10.2904C1.3042 12.1287 2.7992 13.6237 4.63753 13.6237C6.47587 13.6237 7.97087 12.1287 7.97087 10.2904C7.97087 8.45203 6.47587 6.95703 4.63753 6.95703C2.7992 6.95703 1.3042 8.45203 1.3042 10.2904Z"
                      fill="#34B1E2"
                    />
                    <path
                      d="M6.52197 3.33333C6.52197 5.17167 8.01697 6.66667 9.85531 6.66667C11.6936 6.66667 13.1886 5.17167 13.1886 3.33333C13.1886 1.495 11.6936 0 9.85531 0C8.01697 0 6.52197 1.495 6.52197 3.33333Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {sidebarOpen && <span>Customer Master</span>}
              </Link>
              {!sidebarOpen && (
                <span className="menu_text"><Link className="nav-link ps-0" to="/custommaster">Customer Master</Link></span>
              )}
            </div>
          </li>
        )}
        {(userType == "ADMIN" || (userType == "ROTEX" && rotexSPTabsAccess == true)) && (
          <li
            className={
              pathname === "/suppliermaster" ||
              pathname == "/inactivesuppliermaster"
                ? "active  nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to="/suppliermaster">
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.16667 20.0002V17.5002C9.16667 16.1427 8.08333 15.0443 6.735 15.0068L4.58333 17.5002L2.39917 15.0102C1.06583 15.0643 0 16.1535 0 17.5002V20.0002M20 20.0002V17.5002C20 16.1427 18.9167 15.0443 17.5683 15.0068L15.4167 17.5002L13.2325 15.0102C11.8992 15.0643 10.8333 16.1535 10.8333 17.5002V20.0002"
                      fill="white"
                    />
                    <path
                      d="M12.1375 10.2904C12.1375 12.1287 13.6325 13.6237 15.4709 13.6237C17.3092 13.6237 18.8042 12.1287 18.8042 10.2904C18.8042 8.45203 17.3092 6.95703 15.4709 6.95703C13.6325 6.95703 12.1375 8.45203 12.1375 10.2904Z"
                      fill="#34B1E2"
                    />
                    <path
                      d="M1.3042 10.2904C1.3042 12.1287 2.7992 13.6237 4.63753 13.6237C6.47587 13.6237 7.97087 12.1287 7.97087 10.2904C7.97087 8.45203 6.47587 6.95703 4.63753 6.95703C2.7992 6.95703 1.3042 8.45203 1.3042 10.2904Z"
                      fill="#34B1E2"
                    />
                    <path
                      d="M6.52197 3.33333C6.52197 5.17167 8.01697 6.66667 9.85531 6.66667C11.6936 6.66667 13.1886 5.17167 13.1886 3.33333C13.1886 1.495 11.6936 0 9.85531 0C8.01697 0 6.52197 1.495 6.52197 3.33333Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {sidebarOpen && <span>Supplier Master</span>}
              </Link>
              {!sidebarOpen && (
                <span className="menu_text"> <Link className="nav-link ps-0" to="/suppliermaster">Supplier Master</Link></span>
              )}
            </div>
          </li>
        )}

        {userType == "CP" && (
          <li
            className={
              pathname == `/inventorylist/${userChannelId}` ||
              pathname == `/Inactiveinventorylist/${userChannelId}`
                ? "active nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to={`/inventorylist/${userChannelId}`}>
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33332 0H5.83333C7.67427 0 9.16665 1.49238 9.16665 3.33332V5.83333C9.16665 7.67427 7.67427 9.16665 5.83333 9.16665H3.33332C1.49238 9.16669 0 7.67431 0 5.83333V3.33332C0 1.49238 1.49238 0 3.33332 0Z"
                      fill="white"
                    />
                    <path
                      d="M14.1667 0H16.6667C18.5076 0 20 1.49238 20 3.33332V5.83333C20 7.67427 18.5076 9.16665 16.6667 9.16665H14.1667C12.3258 9.16665 10.8334 7.67427 10.8334 5.83333V3.33332C10.8333 1.49238 12.3257 0 14.1667 0Z"
                      fill="white"
                    />
                    <path
                      d="M3.33332 10.834H5.83333C7.67427 10.834 9.16665 12.3264 9.16665 14.1673V16.6673C9.16665 18.5083 7.67427 20.0006 5.83333 20.0006H3.33332C1.49238 20.0007 0 18.5083 0 16.6674V14.1673C0 12.3264 1.49238 10.834 3.33332 10.834Z"
                      fill="white"
                    />
                    <path
                      d="M14.1666 10.834H16.6666C18.5075 10.834 19.9999 12.3264 19.9999 14.1673V16.6673C19.9999 18.5083 18.5075 20.0007 16.6666 20.0007H14.1666C12.3256 20.0007 10.8333 18.5083 10.8333 16.6674V14.1673C10.8332 12.3264 12.3256 10.834 14.1666 10.834Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {sidebarOpen && <span className="menu_text">Dashboard</span>}
              </Link>
              {!sidebarOpen && (
                <span className="menu_text"><Link className="nav-link" to={`/inventorylist/${userChannelId}`}>Dashboard</Link> </span>
              )}
            </div>
          </li>
        )}
        {hideCpTabs()  && (
          <li
            className={
              pathname === "/subcategorylist" ||
              pathname === "/categorylist" ||
              pathname === "/productmasterlist" ||
              pathname === "/unitlist" ||
              pathname === "/industrylist" ||
              pathname === "/inactiveproductmaster" ||
              pathname == "inactivecategorylist" ||
              pathname === "/inactivesubcategorylist" ||
              pathname === "/inactiveunitlist" ||
              pathname === "/inactiveindustrylist"
                ? "active  nav-item"
                : "nav-item"
            }
          >
            <div className="dropdown">
              <div className="d-flex align-items-center gap-2">
                <Link className="nav-link" to="/productmasterlist">
                  <div className="icon-image">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8296 0.000183105H4.16391C1.86288 0.000183105 -0.00244141 1.8655 -0.00244141 4.16649C-0.00244141 5.54708 1.11677 6.66629 2.49736 6.66629H17.4962C18.8122 6.68235 19.9105 5.66563 19.996 4.3523C20.0963 2.05119 18.3122 0.104433 16.0111 0.0041281C15.9506 0.00147206 15.8901 0.000183105 15.8296 0.000183105Z"
                        fill="#34B1E2"
                      />
                      <path
                        d="M18.3296 8.33319H1.66431C1.20411 8.33319 0.831055 8.70625 0.831055 9.16645V15.8326C0.833789 18.1325 2.69751 19.9962 4.99736 19.9989H14.9966C17.2964 19.9962 19.1601 18.1325 19.1629 15.8326V9.16645C19.1629 8.70625 18.7898 8.33319 18.3296 8.33319ZM12.4968 12.4995H7.49716C7.03697 12.4995 6.66391 12.1265 6.66391 11.6663C6.66391 11.2061 7.03697 10.833 7.49716 10.833H12.4968C12.957 10.833 13.33 11.2061 13.33 11.6663C13.33 12.1265 12.957 12.4995 12.4968 12.4995Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  {sidebarOpen && (
                    <div className="dropdown-content product">
                      <div className="flex_div">
                        <Link to="/productmasterlist">Product Master</Link>
                        <Link to="/categorylist">Category</Link>
                        <Link to="/subcategorylist">Sub Category </Link>
                        <Link to="/unitlist">Units</Link>
                        <Link to="/industrylist">Industries Application</Link>
                      </div>
                    </div>
                  )}
                </Link>
                {}

                {!sidebarOpen && (
                  <div className="dropdown">
                    <span
                      class=" dropdown-toggle menu_text"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Product Master
                    </span>

                    <ul className="dropdown-menu product_master_ul">
                      <li>
                        {" "}
                        <Link to="/productmasterlist">Product Master</Link>
                      </li>
                      <li>
                        <Link to="/categorylist">Category</Link>
                      </li>
                      <li>
                        <Link to="/subcategorylist">Sub Category </Link>
                      </li>
                      <li>
                        <Link to="/unitlist">Units</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/industrylist">Industries Application</Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </li>
        )}
        {hideSpTabs()  && (
          <li
            className={
              pathname === "/childpartmaster" ||
              pathname === "/childpartmaster/category" ||
              pathname === "/childpartmaster/subcategory" ||
              pathname === "/childpartmaster/units" ||
              pathname === "/childpartmaster/industries" ||
              pathname === "/inactivechildpartmaster" ||
              pathname == "/childpartmaster/inactivecategory" ||
              pathname === "/childpartmaster/inactivesubcategory" ||
              pathname === "/childpartmaster/inactiveunits" ||
              pathname === "/childpartmaster/inactiveindustries"
                ? "active  nav-item"
                : "nav-item"
            }
          >
            <div className="dropdown">
              <div className="d-flex align-items-center gap-2">
                <Link className="nav-link" to="/childpartmaster">
                  <div className="icon-image">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8296 0.000183105H4.16391C1.86288 0.000183105 -0.00244141 1.8655 -0.00244141 4.16649C-0.00244141 5.54708 1.11677 6.66629 2.49736 6.66629H17.4962C18.8122 6.68235 19.9105 5.66563 19.996 4.3523C20.0963 2.05119 18.3122 0.104433 16.0111 0.0041281C15.9506 0.00147206 15.8901 0.000183105 15.8296 0.000183105Z"
                        fill="#34B1E2"
                      />
                      <path
                        d="M18.3296 8.33319H1.66431C1.20411 8.33319 0.831055 8.70625 0.831055 9.16645V15.8326C0.833789 18.1325 2.69751 19.9962 4.99736 19.9989H14.9966C17.2964 19.9962 19.1601 18.1325 19.1629 15.8326V9.16645C19.1629 8.70625 18.7898 8.33319 18.3296 8.33319ZM12.4968 12.4995H7.49716C7.03697 12.4995 6.66391 12.1265 6.66391 11.6663C6.66391 11.2061 7.03697 10.833 7.49716 10.833H12.4968C12.957 10.833 13.33 11.2061 13.33 11.6663C13.33 12.1265 12.957 12.4995 12.4968 12.4995Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  {sidebarOpen && (
                    <div className="dropdown-content product">
                      <div className="flex_div">
                        <Link to="/childpartmaster">Child Part Master</Link>
                        <Link to="/childpartmaster/category">
                          Child Part Category
                        </Link>
                        <Link to="/childpartmaster/subcategory">
                          Child Part Sub Category{" "}
                        </Link>
                        <Link to="/childpartmaster/units">
                          Child Part Units
                        </Link>
                        <Link to="/childpartmaster/industries">
                          Child Part Industries Application
                        </Link>
                      </div>
                    </div>
                  )}
                </Link>
                {}

                {!sidebarOpen && (
                  <div className="dropdown">
                    <span
                      class=" dropdown-toggle menu_text"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Child Part Master
                    </span>

                    <ul className="dropdown-menu product_master_ul">
                      <li>
                        {" "}
                        <Link to="/childpartmaster">Child Part Master</Link>
                      </li>
                      <li>
                        <Link to="/childpartmaster/category">
                          {" "}
                          Child Part Category
                        </Link>
                      </li>
                      <li>
                        <Link to="/childpartmaster/subcategory">
                          {" "}
                          Child Part Sub Category{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/childpartmaster/units">
                          {" "}
                          Child Part Units
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/childpartmaster/industries">
                          {" "}
                          Child Part Industries Application
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </li>
        )}

        {hideCpTabs()  && (
          <li
            className={
              pathname === "/consumptionlist" ||
              pathname === "/inactiveconsumptionlist"
                ? "active  nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to="/consumptionlist">
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3_1618)">
                      <path
                        d="M3.50981e-05 6.68333C3.50981e-05 6.67417 3.50981e-05 6.665 3.50981e-05 6.65583C0.0108684 6.58083 0.0208684 6.5125 0.0325351 6.4375L1.00754 3.0225C1.51837 1.23333 3.15337 0 5.0142 0H5.83337V3.33333C5.83337 3.79333 6.2067 4.16667 6.6667 4.16667C7.1267 4.16667 7.50004 3.79333 7.50004 3.33333V0H12.5V3.33333C12.5 3.79333 12.8725 4.16667 13.3334 4.16667C13.7942 4.16667 14.1667 3.79333 14.1667 3.33333V0H14.985C16.845 0 18.48 1.23333 18.9917 3.02167L19.9675 6.435C19.9784 6.50667 19.9892 6.57917 20 6.65083C20.005 7.78333 19.4425 8.78083 18.5834 9.3875C17.5475 8.72333 16.3192 8.33333 15 8.33333C13.3159 8.33333 11.78 8.96583 10.605 10H9.1667C8.1667 10 7.27837 9.54833 6.6667 8.84833C6.05504 9.54833 5.1667 10 4.1667 10H3.29504C1.46837 10 -0.00829824 8.50917 3.50981e-05 6.68333ZM10.605 20H4.1667C2.32587 20 0.833368 18.5075 0.833368 16.6667V11.0083C1.57504 11.435 2.4167 11.6667 3.29504 11.6667H4.1667C5.0517 11.6667 5.91254 11.4267 6.6667 10.985C7.42087 11.4267 8.2817 11.6667 9.1667 11.6667H9.23503C8.66504 12.6483 8.33337 13.785 8.33337 15C8.33337 16.9917 9.21587 18.7775 10.605 20Z"
                        fill="white"
                      />
                      <path
                        d="M15 10C12.2383 10 10 12.2383 10 15C10 17.7617 12.2383 20 15 20C17.7617 20 20 17.7617 20 15C20 12.2383 17.7617 10 15 10ZM18.0692 14.76L15.8167 16.9383C15.44 17.31 14.94 17.4975 14.4392 17.4975C13.9383 17.4975 13.4367 17.31 13.0533 16.9358L11.925 15.8258C11.5958 15.5033 11.5917 14.9758 11.9133 14.6475C12.235 14.3183 12.7625 14.3133 13.0917 14.6358L14.22 15.745C14.34 15.8633 14.5367 15.8608 14.655 15.7433L16.9158 13.5567C17.2467 13.2392 17.775 13.2483 18.0942 13.5808C18.4125 13.9125 18.4017 14.44 18.07 14.7592L18.0692 14.76Z"
                        fill="#34B1E2"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_1618">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {sidebarOpen && <span>Consumption Master</span>}
              </Link>
              {!sidebarOpen && (
                <span className="menu_text">  <Link className="nav-link p-0" to="/consumptionlist">Consumption Master</Link></span>
              )}
            </div>
          </li>
        )}

{/* {(userType != "SP" && userType != "CP") && (
          <li
            className={
              pathname === "/rotex/consumption" ||
              pathname === "/rotex/consumption"
                ? "active  nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to="/rotex/consumption">
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3_1618)">
                      <path
                        d="M3.50981e-05 6.68333C3.50981e-05 6.67417 3.50981e-05 6.665 3.50981e-05 6.65583C0.0108684 6.58083 0.0208684 6.5125 0.0325351 6.4375L1.00754 3.0225C1.51837 1.23333 3.15337 0 5.0142 0H5.83337V3.33333C5.83337 3.79333 6.2067 4.16667 6.6667 4.16667C7.1267 4.16667 7.50004 3.79333 7.50004 3.33333V0H12.5V3.33333C12.5 3.79333 12.8725 4.16667 13.3334 4.16667C13.7942 4.16667 14.1667 3.79333 14.1667 3.33333V0H14.985C16.845 0 18.48 1.23333 18.9917 3.02167L19.9675 6.435C19.9784 6.50667 19.9892 6.57917 20 6.65083C20.005 7.78333 19.4425 8.78083 18.5834 9.3875C17.5475 8.72333 16.3192 8.33333 15 8.33333C13.3159 8.33333 11.78 8.96583 10.605 10H9.1667C8.1667 10 7.27837 9.54833 6.6667 8.84833C6.05504 9.54833 5.1667 10 4.1667 10H3.29504C1.46837 10 -0.00829824 8.50917 3.50981e-05 6.68333ZM10.605 20H4.1667C2.32587 20 0.833368 18.5075 0.833368 16.6667V11.0083C1.57504 11.435 2.4167 11.6667 3.29504 11.6667H4.1667C5.0517 11.6667 5.91254 11.4267 6.6667 10.985C7.42087 11.4267 8.2817 11.6667 9.1667 11.6667H9.23503C8.66504 12.6483 8.33337 13.785 8.33337 15C8.33337 16.9917 9.21587 18.7775 10.605 20Z"
                        fill="white"
                      />
                      <path
                        d="M15 10C12.2383 10 10 12.2383 10 15C10 17.7617 12.2383 20 15 20C17.7617 20 20 17.7617 20 15C20 12.2383 17.7617 10 15 10ZM18.0692 14.76L15.8167 16.9383C15.44 17.31 14.94 17.4975 14.4392 17.4975C13.9383 17.4975 13.4367 17.31 13.0533 16.9358L11.925 15.8258C11.5958 15.5033 11.5917 14.9758 11.9133 14.6475C12.235 14.3183 12.7625 14.3133 13.0917 14.6358L14.22 15.745C14.34 15.8633 14.5367 15.8608 14.655 15.7433L16.9158 13.5567C17.2467 13.2392 17.775 13.2483 18.0942 13.5808C18.4125 13.9125 18.4017 14.44 18.07 14.7592L18.0692 14.76Z"
                        fill="#34B1E2"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_1618">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {sidebarOpen && <span>Rotex Consumption Master</span>}
              </Link>
              {!sidebarOpen && (
                <span className="menu_text"> Rotex Consumption </span>
              )}
            </div>
          </li>
        )} */}

{(userType != "ROTEX" && userType != "CP") && (
          <li
            className={
              pathname === "/supplier/consumption" ||
              pathname === "/supplier/consumption"
                ? "active  nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to="/supplier/consumption">
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3_1618)">
                      <path
                        d="M3.50981e-05 6.68333C3.50981e-05 6.67417 3.50981e-05 6.665 3.50981e-05 6.65583C0.0108684 6.58083 0.0208684 6.5125 0.0325351 6.4375L1.00754 3.0225C1.51837 1.23333 3.15337 0 5.0142 0H5.83337V3.33333C5.83337 3.79333 6.2067 4.16667 6.6667 4.16667C7.1267 4.16667 7.50004 3.79333 7.50004 3.33333V0H12.5V3.33333C12.5 3.79333 12.8725 4.16667 13.3334 4.16667C13.7942 4.16667 14.1667 3.79333 14.1667 3.33333V0H14.985C16.845 0 18.48 1.23333 18.9917 3.02167L19.9675 6.435C19.9784 6.50667 19.9892 6.57917 20 6.65083C20.005 7.78333 19.4425 8.78083 18.5834 9.3875C17.5475 8.72333 16.3192 8.33333 15 8.33333C13.3159 8.33333 11.78 8.96583 10.605 10H9.1667C8.1667 10 7.27837 9.54833 6.6667 8.84833C6.05504 9.54833 5.1667 10 4.1667 10H3.29504C1.46837 10 -0.00829824 8.50917 3.50981e-05 6.68333ZM10.605 20H4.1667C2.32587 20 0.833368 18.5075 0.833368 16.6667V11.0083C1.57504 11.435 2.4167 11.6667 3.29504 11.6667H4.1667C5.0517 11.6667 5.91254 11.4267 6.6667 10.985C7.42087 11.4267 8.2817 11.6667 9.1667 11.6667H9.23503C8.66504 12.6483 8.33337 13.785 8.33337 15C8.33337 16.9917 9.21587 18.7775 10.605 20Z"
                        fill="white"
                      />
                      <path
                        d="M15 10C12.2383 10 10 12.2383 10 15C10 17.7617 12.2383 20 15 20C17.7617 20 20 17.7617 20 15C20 12.2383 17.7617 10 15 10ZM18.0692 14.76L15.8167 16.9383C15.44 17.31 14.94 17.4975 14.4392 17.4975C13.9383 17.4975 13.4367 17.31 13.0533 16.9358L11.925 15.8258C11.5958 15.5033 11.5917 14.9758 11.9133 14.6475C12.235 14.3183 12.7625 14.3133 13.0917 14.6358L14.22 15.745C14.34 15.8633 14.5367 15.8608 14.655 15.7433L16.9158 13.5567C17.2467 13.2392 17.775 13.2483 18.0942 13.5808C18.4125 13.9125 18.4017 14.44 18.07 14.7592L18.0692 14.76Z"
                        fill="#34B1E2"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_1618">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {sidebarOpen && <span>Supplier Consumption Master</span>}
              </Link>
              {!sidebarOpen && (
                <span className="menu_text  "><Link className="nav-link p-0" to="/supplier/consumption">Supplier Consumption</Link>  </span>
              )}
            </div>
          </li>
        )}
        {userType == "ADMIN" && (
          <li
            className={
              pathname === "/userlist" || pathname === "/inactiveuserlist"
                ? "active  nav-item"
                : "nav-item"
            }
          >
            <div className="d-flex align-items-center gap-2">
              <Link className="nav-link" to="/userlist">
                <div className="icon-image">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_3_1628)">
                      <path
                        d="M12.0833 5.83333C10.475 5.83333 9.16663 4.525 9.16663 2.91667C9.16663 1.30833 10.475 0 12.0833 0C13.6916 0 15 1.30833 15 2.91667C15 4.525 13.6916 5.83333 12.0833 5.83333ZM4.5833 5.83333C2.97497 5.83333 1.66663 4.525 1.66663 2.91667C1.66663 1.30833 2.97497 0 4.5833 0C6.19163 0 7.49997 1.30833 7.49997 2.91667C7.49997 4.525 6.19163 5.83333 4.5833 5.83333ZM18.0691 14.76L15.8166 16.9383C15.44 17.31 14.94 17.4975 14.4391 17.4975C13.9383 17.4975 13.4366 17.31 13.0533 16.9358L11.925 15.8258C11.5958 15.5033 11.5916 14.9758 11.9133 14.6475C12.235 14.3183 12.7625 14.3133 13.0916 14.6358L14.22 15.745C14.34 15.8633 14.5366 15.8608 14.655 15.7433L16.9158 13.5567C17.2466 13.2392 17.775 13.2483 18.0941 13.5808C18.4125 13.9125 18.4016 14.44 18.07 14.7592L18.0691 14.76ZM3.74997 9.58333C3.74997 8.47917 4.14247 7.465 4.79497 6.67333C4.71747 6.66917 4.63913 6.66667 4.5608 6.66667C2.2208 6.66667 0.262465 8.41917 0.00496496 10.7417C-0.0208684 10.9775 0.054965 11.2125 0.212465 11.3892C0.369965 11.5658 0.596632 11.6667 0.833298 11.6667H4.25163C3.9308 11.0417 3.74997 10.3333 3.74997 9.58333ZM8.3333 12.5C8.50497 12.5 8.67247 12.485 8.8358 12.4567C9.3358 11.2475 10.18 10.2175 11.2475 9.48917C11.1975 7.925 9.90913 6.6675 8.33247 6.6675C6.72413 6.6675 5.4158 7.97583 5.4158 9.58417C5.4158 11.1925 6.72413 12.5008 8.33247 12.5008L8.3333 12.5ZM8.3333 15C8.3333 14.4408 8.40247 13.8975 8.53247 13.3783C8.3233 13.3492 8.1108 13.3333 7.89413 13.3333C5.55413 13.3333 3.5958 15.0858 3.3383 17.4083C3.31247 17.6442 3.3883 17.8792 3.5458 18.0558C3.7033 18.2325 3.92997 18.3333 4.16663 18.3333H9.22497C8.65746 17.3525 8.3333 16.2142 8.3333 15Z"
                        fill="white"
                      />
                      <path
                        d="M15 10C12.2383 10 10 12.2383 10 15C10 17.7617 12.2383 20 15 20C17.7617 20 20 17.7617 20 15C20 12.2383 17.7617 10 15 10ZM18.0692 14.76L15.8167 16.9383C15.44 17.31 14.94 17.4975 14.4392 17.4975C13.9383 17.4975 13.4367 17.31 13.0533 16.9358L11.925 15.8258C11.5958 15.5033 11.5917 14.9758 11.9133 14.6475C12.235 14.3183 12.7625 14.3133 13.0917 14.6358L14.22 15.745C14.34 15.8633 14.5367 15.8608 14.655 15.7433L16.9158 13.5567C17.2467 13.2392 17.775 13.2483 18.0942 13.5808C18.4125 13.9125 18.4017 14.44 18.07 14.7592L18.0692 14.76Z"
                        fill="#34B1E2"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_1628">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                {sidebarOpen && <span>Users List</span>}
              </Link>
              {!sidebarOpen && <span className="menu_text"><Link className="nav-link ps-0" to="/userlist">Users List </Link> </span>}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Sidebar;
